.calculatorContainer {
    display: flex;
    flex-direction: column  ;
}

.coverContainer {
    display: flex;
    margin: 50px auto 0 auto;
}

.coverc {
    width: 474px;
    height: 316px;
}

.formContainer form {
    margin: auto;
    color: red;
}

@media only screen and (max-width: 1250px) {
    .coverContainer {
        display: flex;
        flex-direction: column;
    }

    .coverc {
        margin: auto;
        width: 350px;
        height: 233.33px;
    }
}