

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    margin-top: 100px;
}

h1 {
    color: #F96D22;
    font-size: 32px;
    margin:0px;
    inline-size: fit-content;
    font-family: cursive;
}

h2{
color: #252525;
font-size: 62px;
margin:0px;
inline-size: fit-content;
font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.cover {
    display: flex;
    justify-content: center;
    margin-bottom: 75px;
}

.coverp{
   width: 475px;
   height: 475px;
}




.infoC{
    display: flex;
    width: fit-content;
}

.query {
    width: 15vw;
    position:absolute;
    border-bottom: #F96D22 5px solid;
    margin-left: 30px;
}

h3 {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 32px;
    margin: 5px;
}

span {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 24px;
    text-align: left !important;
}

.trio {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-left: 10vw;
    height: auto;
    margin-bottom: 100px;
}

.card {
    width: 325px;
    height: 350px !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1400px) {

.cover{
    flex-direction: column;
    justify-items: center;
}

.header{
    margin-top: 200px !important;
    margin: auto;
}

.coverp{
    margin: auto;
}


.trio{
    flex-direction: column;
    margin-top: 250px;
}

.card{
    margin: auto;
    margin-bottom: 50px;
    height: 400px !important;
}

.infoC {
    margin-top: 250px;
}
}

@media only screen and (max-width: 800px) {
.trio {
    margin: auto;
    justify-content:center;
}

.query{
    width: auto;
    position: relative;

}
.coverp {
    width: 275px !important;
    height: 275px !important;
}

.infoC{
    margin-bottom: 150px;
}
h2{
    text-align: left;
    margin-left: 50px;
}
}
