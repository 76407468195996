.recipeC {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}

.recipeInfo {
  margin-top: 70px;
  width: 30vw;
  height: auto;
}
.cover {
  height: 475px;

}
.tutor {
  text-align: left;
  margin-left: 20vw;
  width: 25vw;
  border-bottom: #f96d22 5px solid;

}

@media only screen and (max-width: 1400px) {
.recipeInfo{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

  .cover {
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .coverp {
    margin: auto;
  }

  .recipeC {
      align-items: center;
  }

  .tutor {
    margin-left: 0px !important;
width: 80vw;
  }
}


@media only screen and (max-width: 500px) {
.recipeInfo{
    width: 90vw;
}
h1{
    font-size: 30px;
}

h2 {
    font-size: 35px !important;
    margin-left: 0px;
    text-align: center;
}
}