.nav {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 75px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.orange {
  display: none;
}

.nav a {
  text-decoration: none;
  border-radius: 3px;
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #252525;
  margin: auto;
  transition: color .1s ease-in-out, color .1s ease-in-out;
}

.nav a:hover {
  text-decoration: solid underline #E25306 4px;
  color: white;
    background-color: #d1ffff rgba(0, 0, 0, 0.5);
    color: #F96D22;
    ;}
  
  .nav a.active {
    text-decoration: solid underline #E25306 4px;
  }

.fork {
    margin-right: 3vw;
}

.mobileNav {
  display: none;
}

@media only screen and (max-width: 1400px) {

  .orange{
display: flex;
width: 150px;
margin: auto;
margin-top: 50px;
  }

.mobileNav {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  height: 75px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: fixed;
  background-color: #FFFF;
  z-index: 9999;
}

.cover{
  margin-top: 110px;
}
.mobileNav a {
  text-decoration: none;
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #252525;
  margin-top: 10px;
}

.nav {
  display: none;
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height:100vh;
  background-image: linear-gradient(to bottom, #FFFF,#E25306);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  position: fixed;
  z-index: 8888;
  margin-top: 75px;
}

.mobileLinks a {
  text-decoration: none;
  font-size: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: #252525;
  margin-top: 40px;
}

.mobileLinks a:hover {
  background-color: #d1ffff rgba(0, 0, 0, 0.5);
  color: #F96D22;
}

.mobileLinks a.active {
  color: #E25306;
  opacity: 0.75;
}

h2 {
  font-size: 40px;
}
}